<template>
  <div class="col">
    <div v-if="heathiansTop && heathiansTop.url">
      <b-img fluid class="w-100 h-100" :src="heathiansTop.url.fileUrl"></b-img>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "healthians-top-banner",
  computed: {
    ...mapState({
      heathiansTop: ({ advertisement }) => advertisement.heathiansTop,
    }),
  },
  methods: {
    ...mapActions({
      fetchHealthiansTopBannerData: "advertisement/healthiansWebTop",
    }),
  },
  mounted() {
    this.fetchHealthiansTopBannerData();
  },
};
</script>
